import React from "react"
import { inject } from "mobx-react"

@inject('store')
class FlightGrid extends React.Component {
  constructor () {
    super()
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  cabin_row = [];
  state = {
    renderable: false,
    cta_position_top: 0,
    basic_window_width: 0,
  }
  grouped_flights_list = {};
  flight_destination_list = [];
  destination_list = [];
  only_london = true;
  from_destiantion = '';
  fb_class = '';
  
	componentDidMount() { 
    const { destination_id, category } = this.props;
    const destinationJoined = destination_id.join(',');
    if (category === 'f&b') {
      this.fb_class = "fb-grid";
      this.cabin_row = [
        {cabin: 'P', cabin_name: 'Premium'},
        {cabin: 'B', cabin_name: 'Business'},
        {cabin: 'F', cabin_name: 'First'}
      ];
    }
    else {
      this.cabin_row = [
        {cabin: 'E', cabin_name: 'Economy'},
        {cabin: 'P', cabin_name: 'Premium'},
        {cabin: 'B', cabin_name: 'Business'},
        {cabin: 'F', cabin_name: 'First'}
      ];
    }

    var groupBy = function(xs, key) {
      return xs.reduce(function(rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };

    // To test this in dev mode, enable proxy in gatsby-config.js and use the url without the domain name. (/api/...)
    fetch("https://www.flightcentre.co.uk/api/fcluk_content/flight?destination=" + destinationJoined, {
      method: 'GET'
    }).then((res) => res.json())
    .then((data) =>  { 
      this.grouped_flights_list = groupBy(data.flights, 'parent_destination_tid');
      // Get unique destiantion list from flights list.
      data.flights.forEach( flight_element => {
        if (this.destination_list.indexOf(flight_element.parent_destination_tid) === -1) {
          this.destination_list.push(flight_element.parent_destination_tid);
          this.flight_destination_list.push({parent_destination_tid: flight_element.parent_destination_tid, parent_destination_name: flight_element.parent_destination_name});
        }
        if (this.only_london && flight_element.origin_parent_name !== 'London') {
          this.only_london = false;
        }
      });
      if (this.only_london) {
        this.from_destiantion = "London";
      }
      else {
        this.from_destiantion = "England";
      }
      this.setState({renderable: true});
    }).catch((err) => console.log(err)); 

    window.addEventListener("resize", this.updateDimensions);
  }

  openCta = element => {
    this.setState({basic_window_width: window.innerWidth})
    this.props.store.isOpenedCTA = 'block'
    // @TODO have to implement break points in state manager!!
    if (window.innerWidth >= 996) {
      this.props.store.ctaPositionTop = element.pageY
    }
    else {
      this.props.store.ctaPositionTop = 0
    }
  }

  updateDimensions() {
    // @TODO have to implement break points in state manager!!
    if (window.innerWidth < 996) {
      this.props.store.ctaPositionTop = 0
      this.setState({basic_window_width: 0})
    }
    // @TODO have to implement break points in state manager!!
    if (window.innerWidth >= 996 && this.state.basic_window_width < 996) {
      this.props.store.isOpenedCTA = 'none'
    }
  }

	render() {
    var destination_name = this.from_destiantion;
    var _flight_destination_list = this.flight_destination_list;
    var _cabin_row = this.cabin_row;
    var _grouped_list = this.grouped_flights_list;
    var openCta = this.openCta
		return(
      this.state.renderable &&
			<>
				<div className={`flight-grid-wrapper ${this.fb_class}`}>
					<h2 className="flight-grid-title">Top Destination Airfares & Flight Deals</h2>
          <div className="flight-grid">
            <div className="fg-cabin-header">
              {this.cabin_row.map(function(element) {
                return (<div className="fg-cabin" key={element.cabin_name}>{element.cabin_name}</div>)
              })}
            </div>
            {this.destination_list.map( function(des_tid) {
                return (
                  _flight_destination_list.map( function(parent_destination) {
                    if (parent_destination.parent_destination_tid === des_tid) {
                      return (
                        <>
                          <div className="fg-row-container">
                            <div className="fg-route">
                              <div className="fg-origin-destination">
                                <span className="fg-origin">{ destination_name }</span>
                                to
                                <div className="fg-destination">
                                  { parent_destination.parent_destination_name }
                                </div>
                              </div>
                            </div>
                            <div className="fg-prices">
                              {
                                _cabin_row.map( function(cabin_element) {
                                  var price_found = false;
                                  return (
                                    _grouped_list[des_tid].map( function(item, i) {
                                      if (item.cabin === cabin_element.cabin) {
                                        price_found = true;
                                        return (
                                          <>
                                            <div className="fg-class-price" onClick={openCta}>
                                              <div className="fg-class">{cabin_element.cabin_name}</div>
                                              <div className="fg-price-wrapper">
                                                <span className="fg-return-text">Return from</span>
                                                <div className="fg-price"><span className="fg-currency">&pound;</span><span className="fg-total">{item.total_price}</span><span className="fg-price-suffix">pp</span></div>
                                              </div>
                                            </div>
                                          </>
                                        )
                                      } 
                                      if (i === _grouped_list[des_tid].length - 1 && !price_found) {
                                        return (
                                          <>
                                            <div className="fg-class-price no-price">
                                              <div className="fg-class">{cabin_element.cabin_name}</div>
                                              <div className="fg-call-us-wrapper">
                                                <span className="fg-cta-text"><span className="fg-icon"></span>
                                                  Call us on
                                                </span>
                                                <a href="tel:08005870058" className="InfinityNumber" itemProp="telephone" data-attr="phone-number-landline">0800 587 0058</a>
                                              </div>
                                            </div>
                                          </>
                                        )
                                      } 
                                    })
                                  )
                                })}
                            </div>
                          </div>
                        </>
                      )
                    }
                  })
                )
            })}
          </div>
        </div>
			</>
		);
  }
  
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }
}

export default FlightGrid;
