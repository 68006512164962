import React from "react";
import $ from 'jquery'
import CallBackFormWidget from "../components/callback-form-widget"
import { scroller } from "react-scroll"
import iconCall from '../images/icon-call.svg'
import iconCallBack from '../images/icon-call-back-wh.svg'
import iconEmail from '../images/icon-email-wh.svg'
//import iconLiveChat from '../images/icon-live-chat.svg'

class CtaWidgets extends React.Component {

  onMobileCallBackButtonClick = event => {
    $(window).scrollTop(0);
    $('.fullscreen-overlay').fadeIn();
    $('body,html').css('overflow', 'hidden');
  }

  onGetAQuoteButtonClick = event => {
    scroller.scrollTo('enquiry-form-anchor', {
      duration: 500,
      smooth: true,
      offset: -20
    })
  }

  componentDidMount() {

  }

  render() {
    const { category } = this.props;
    var utcTime = new Date().getUTCHours()
    if (utcTime > 7 && utcTime < 18) {
      // Stores open
      return (
        <>
          <div className='columns is-hidden-touch'>
            <div className='column'>
              <div className='widget-box'>
                <h3 className='title is-3'>Call Us - Free</h3>
                <div className='icon'><img alt="Call Us - Free" src={iconCall} /></div>
                <a href="tel:08005870058" className="InfinityNumber desktop-header-phone-call blue-button" itemProp="telephone" data-attr="phone-number-landline">0800 587 0058</a>
              </div>
            </div>
            <div className='column'>
              <div className='widget-box call-back'>
                <h3 className='title is-3'>Get a Callback</h3>
                <div className='icon'><img alt="Call Us - Free" src={iconCallBack} /></div>
                <CallBackFormWidget category={category} />
              </div>
            </div>
            <div className='column'>
              <div className='widget-box email-us'>
                <h3 className='title is-3'>Email Us</h3>
                <div className='icon'><img alt="Call Us - Free" src={iconEmail} /></div>
                <button className="blue-button" onClick={this.onGetAQuoteButtonClick}>Get a quote</button>
              </div>
            </div>
          </div>
          <div className='columns is-hidden-desktop mobile-widgets'>
            <div className='column'>
              <a href="tel:08005870058" className="InfinityNumber desktop-header-phone-call blue-button" itemProp="telephone" data-attr="phone-number-landline">0800 587 0058</a>
            </div>
            <div className='column'>
              <button className='cta-mobile-button callBackButtonMobile' onClick={this.onMobileCallBackButtonClick}>Call back <span></span></button>
              <button className='cta-mobile-button emailButtonMobile' onClick={this.onGetAQuoteButtonClick}>E-mail <span></span></button>
            </div>
          </div>
        </>
      )
    }
    else {
      // Stores closed
      return (
        <div className='columns'>
          <div className='column'>
            <button className="blue-button" onClick={this.onGetAQuoteButtonClick}>Get a quote</button>
          </div>
        </div>
      )
    }
  }
}

export default CtaWidgets;
