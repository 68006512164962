import React, { Component } from "react";
import PhoneInput from "react-phone-number-input/basic-input"

class CallBackWidget extends Component {
  state = {
    targetNumber: '',
    serviceID: null,
    callBackPhase1: true,
    callBackPhase2: false,
    callBackPhase3: false,
    invalidNumber: false,
    successCallRequest: false,
  };

  componentDidMount() {
    const { category } = this.props;
    this.setState({
      // @TODO have to remove hardcoded values and connect with D8 db!!
      serviceID: category === 'f&b' ? 6251 : 6020
    });
  }

  isValidPhoneNumber(phone_number) {
    if (phone_number.length < 11) {
      return false;
    }
    else {
      if (phone_number.charAt(0) !== "0") {
        return false;
      }
    }
    return true;
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value,
    })
  }

  handleSubmit = event => {
    event.preventDefault()

    if (!this.isValidPhoneNumber(this.state.targetNumber)) {
      this.setState({invalidNumber: true})
    }
    else {
      this.setState({invalidNumber: false})

      fetch(`https://www.flightcentre.co.uk/api/fcluk_sesui/ctc?form_id=click-to-call&serviceID=${this.state.serviceID}&httpInitNoPIN=y&failURL=${encodeURIComponent('https://www.flightcentre.co.uk')}&successURL=${encodeURIComponent('https://www.flightcentre.co.uk')}&currentURL=${encodeURIComponent(window.location.href)}&targetnumber=${encodeURIComponent(this.state.targetNumber)}&js=true`, {
        method: 'GET'
      }).then((res) => res.json())
      .then((response) =>  {
        let success = true;

        if (typeof response.error !== typeof undefined && response.error === 1) {
          success = false;
        }
        if (typeof response.status !== typeof undefined && response.status !== 200) {
          success = false;
        }
        if (success && (typeof response.result.code === typeof undefined || response.result.code !== 200)) {
          success = false;
        }
        if (success && (typeof response.result.data === typeof undefined || parseInt(response.result.data.substring(0, 3)) !== 200)) {
          success = false;
        }
        if (success) {
          this.setState({ successCallRequest: true})
          this.setState({ callBackPhase3: true })
          this.setState({ callBackPhase2: false })
        }
        else {
          this.setState({ successCallRequest: false})
          this.setState({ callBackPhase3: true })
          this.setState({ callBackPhase2: false })
        }
      }).catch((err) => console.log(err));
    }
  }

  openCallBackPhase2 = () => {
    if (!this.state.callBackPhase2) {
      this.setState({ callBackPhase2: true })
      this.setState({ callBackPhase1: false })
    }
  }

  render() {
    const {
      callBackPhase1,
      callBackPhase2,
      callBackPhase3,
      successCallRequest
    } = this.state
    var invalidNumberClass = ''
    if (this.state.invalidNumber) {
      invalidNumberClass = "invalidNumber"
    }
    return (
    <>
    {
    callBackPhase1 &&
    <div onClick={this.openCallBackPhase2} className="column widget-box call-back callback-pase-1">
      <p className="text-label-1">Callback</p>
      <p className="text-label-2">Instant free call</p>
    </div>
    }
    {
    callBackPhase2 &&
    <div className="column widget-box call-back callback-pase-2">
      <p className="callback-form-label">Enter a valid UK number</p>
      <form className="call-back-form" nls_fa_name="forms/ctc" action="" method="GET" acceptCharset="UTF-8" onSubmit={this.handleSubmit}>
        <div className="columns is-mobile">
        <div className="column input-form">
          <div className="control">
            <PhoneInput country="UK" 
              className="input" 
              value={ this.state.targetNumber } 
              placeholder="Enter your number" 
              onChange={ value => this.setState({ targetNumber: value }) } required />
            <div className={"phoneNumberAlert " + invalidNumberClass}>
              <span>!</span> Please enter a valid UK number
            </div>
          </div>
        </div>
        <div className="column is-narrow">
          <div className="control">
            <button type="submit" className="button"><span></span></button>
          </div>
        </div>
        </div>
        <p className='is-hidden-desktop'>We’ll call you back instantly</p>
      </form>
    </div>
    }
    {
    callBackPhase3 && successCallRequest &&
    <div className="column widget-box call-back callback-pase-3">
      <div className="confirmation-message">
        <p>
          Your request has been correctly forwarded to one of our consultants that will call you back shortly.
        </p>
      </div>
    </div>
    }
    {
    callBackPhase3 && !successCallRequest &&
    <div className="column widget-box call-back callback-pase-3 error">
      <div className="confirmation-message">
        <h3 className='title is-3'>Oh no, its not working. <span>!</span></h3>
        <p>Sorry it doesn’t seem to be working. Try another contact method.</p>
      </div>
    </div>
    }
    </>
    );
  }
}

export default CallBackWidget;
