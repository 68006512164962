import React, { Component } from "react";

class FlightPrice extends Component {

  state = {totalPrice: null}

  componentDidMount() {
    const { destinationTid } = this.props;
    const destinationJoined = destinationTid.join(',');
    // To test this in dev mode, enable proxy in gatsby-config.js and use the url without the domain name. (/api/...)
    const apiUrl = `https://admin.flightcentre.co.uk/api/fcluk_content/flight?destination=${destinationJoined}&type=cheapest`

    fetch(apiUrl, { method: 'GET'}).then((res) => res.json()).then((data) =>  {

      if (data.flights !== undefined && Array.isArray(data.flights) && data.flights[0].total_price !== undefined) {
        this.setState({totalPrice: data.flights[0].total_price})
      }

    }).catch((err) => console.log(err));
  }

  render() {    
    return (
      this.state.totalPrice &&
      <>
      {' '}&pound;{this.state.totalPrice}
      </>
    )
  }
}

export default FlightPrice;
