import React from "react"
import { StaticQuery, graphql } from 'gatsby' 
import Img from "gatsby-image"
//import { Link } from "gatsby"
import allNoTextSvg from '../images/all_travel_no_worries_text.svg'
import footerSundayTimesLogo from '../images/footer-logos/sudnay-times-light.svg'
import footerIataLogo from '../images/footer-logos/iata-light.svg'
import footerAtolLogo from '../images/footer-logos/atol-light.svg'
import footerAbtaLogo from '../images/footer-logos/abta-light.svg'
import footerTravelAwardLogo from '../images/footer-logos/travel-award-2017.svg'
import footerTravelifeLogo from '../images/footer-logos/travelife.svg'

class Layout extends React.Component {
  render() {
    return (
      <StaticQuery
        query={layoutQuery}
        render={data => {
          const { /*location, title,*/ children } = this.props
          //const rootPath = `${__PATH_PREFIX__}/`
          return (
            <>
              <header>
                <div className="container">
                  <div className="header-captain-image"><a href="https://www.flightcentre.co.uk/">&nbsp;</a></div>
                  <div className="logo"><a href="https://www.flightcentre.co.uk/">&nbsp;</a></div>
                  <div className="infinity-number-wrapper">
                    <a href="tel:08005870058" className="InfinityNumber desktop-header-phone-call" itemProp="telephone" data-attr="phone-number-landline">0800 587 0058</a>
                  </div>
                </div>
              </header>
              {children}
              <section className="section pre-footer">
                <div className="container has-text-centered">
                  <img className='all_no_svg' alt="All travel no worries" src={allNoTextSvg} />
                  <Img 
                    className='captainMirrored' 
                    alt="All Travel No Worries" 
                    fixed={data.captainMirroredImage.childImageSharp.fixed} 
                    style={{position: 'absolute'}}
                  />
                </div>
              </section>
              <footer>
                <div className="container has-text-centered">
                  <div className="columns is-multiline is-mobile">
                    <div className="column is-6-mobile is-4-tablet is-2-desktop footer-logo travelife"><img alt="TravelAward" src={footerTravelifeLogo} /></div>
                    <div className="column is-6-mobile is-4-tablet is-2-desktop footer-logo sunday-times"><img alt="Sunday Times" src={footerSundayTimesLogo} /></div>
                    <div className="column is-6-mobile is-4-tablet is-2-desktop footer-logo iata"><img alt="Iata" src={footerIataLogo} /></div>
                    <div className="column is-6-mobile is-4-tablet is-2-desktop footer-logo british-travel"><img alt="TravelAward" src={footerTravelAwardLogo} /></div>
                    <div className="column is-6-mobile is-4-tablet is-2-desktop footer-logo atol"><img alt="Atol" src={footerAtolLogo} /></div>
                    <div className="column is-6-mobile is-4-tablet is-2-desktop footer-logo abta"><img alt="Abta" src={footerAbtaLogo} /></div>
                  </div>
                  <p>
                    © {new Date().getFullYear()} Flight Centre (UK) Limited, Registered in England No. 02937210 * 0800 calls are free for landlines and mobiles.<br/>
                    0333 calls are included within inclusive minutes package on mobiles, otherwise standard rates apply.<br/>
                    0844/0845 calls are 7p/pm plus your local carrier charge.
                  </p>
                </div>
              </footer>
            </>
          )
        }}
      />
    )
  }
}

export default Layout


const layoutQuery = graphql`
  query LayoutQuery {
    captainMirroredImage: file(relativePath: { eq: "captain_mirrored.png" }) {
      childImageSharp {
        fixed(width: 99) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
