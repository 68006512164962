import React from "react"

class CallWidget extends React.Component {
  render() {
    return(
      // @TODO have to remove hardcoded values (phone number) and connect with D8 db!!
      <>
        <div className='column widget-box call' onClick={this.handleClick}>
          <div className="widget call-widget"> 
            <a ref={a => this.callTag = a} href="tel:08005870058" className="InfinityNumber desktop-header-phone-call" itemProp="telephone" data-attr="phone-number-landline">0800 587 0058</a>
            <p className="phone-number-label">Talk to a Travel Expert</p>
          </div>
        </div>
      </>
    )
  }

  handleClick = (e) => {
    this.callTag.click()
  }
}
export default CallWidget 
