import React, { Component, createRef } from "react";
import Downshift from 'downshift'
import DatePicker from 'react-datepicker'
import loadingGif from '../images/loading-large.gif'
//import "react-datepicker/dist/react-datepicker.css";
import PhoneInput from "react-phone-number-input/basic-input"
import GoogleMapReact from 'google-map-react';
import { scroller } from "react-scroll"
import mapMarkerPin from '../images/pin.svg'

var Sortable = null;

function formatDate(tempDate) {
  var formatTwoDigits = (digit) => ("0" + digit).slice(-2);
  var date = `${formatTwoDigits(tempDate.getDate())}/${formatTwoDigits(tempDate.getMonth()+1)}/${tempDate.getFullYear()}`;
  return date
}

function getCurrentDate() {
  return formatDate(new Date());
}

var selectOptions = [];
const successMsgBoxRef = createRef();

class TripPlannerFormContainer extends Component {
  state = {
    downshiftValue: '',
    noDestinationSelectedError: false,
    destinationDropdownVisible: true,
    destinationNextLinkDisabled: true,
    savedTripLegs: [],
    phone: '', 
    formSuccess: false, 
    formError: false, 
    routeLegs: '',
    startDate: null, 
    endDate: null, 
    pageUrl: '',
    keyword: 'ukfc.rtwflights',
    utm_source: '',
    utm_medium: '',
    utm_campaign: '',
    category: null,
    eCommerceProdName: 'RTW Flights',
    form_id: 'trip-planner-map',
    routing_code: '',
    enquiryCategory: 'Round The World - General',
    brand: 'FCUK',
    display_loading_gif: false
  };

  destinations = null

  componentDidMount() {
    if (typeof navigator !== `undefined`) {
      Sortable = require('react-sortablejs');
      this.setState({
        sortableImported: true
      })
    }
    const { category } = this.props;
    // To test this in dev mode, enable proxy in gatsby-config.js and use the url without the domain name. (/api/...)
    fetch("https://www.flightcentre.co.uk/api/fcluk_content/destination?format=json&withIata=true&withLocation=true&return=true", {
      method: 'GET'
    }).then((res) => res.json())
    .then((data) =>  {   
      this.destinations = data.destinations
      this.destinations.forEach(element => {
        selectOptions.push({ value: element.iata, label: element.name + ' ' + element.iata});
      });
    }).catch((err) => console.log(err));

    this.setState({
      category: category
    });

    var url = window.location.search
    var query = url.substr(1);
    var urlParams = {};
    query.split("&").forEach(function(part) {
      var item = part.split("=");
      urlParams[item[0]] = decodeURIComponent(item[1]);
    });
    this.setState({
      pageUrl: window.location.href,
      utm_source: urlParams['utm_source'] !== undefined ? urlParams['utm_source'] : '',
      utm_medium: urlParams['utm_medium'] !== undefined ? urlParams['utm_medium'] : '',
      utm_campaign: urlParams['utm_campaign'] !== undefined ? urlParams['utm_campaign'] : ''
    })
  }

  isValidPhoneNumber(phone_number) {
    if (this.state.category === 'f&b') {
      return true;
    }
    if (phone_number.length < 11) {
      return false;
    }
    else {
      if (phone_number.charAt(0) !== "0") {
        return false;
      }
    }
    return true;
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value,
    })

    if (name === 'departName') {
      var departName = value;
      var departLeg = 'LON';
      var departCoord = {lat: 51.5287718, long: -0.2416814};

      if (departName === 'London') {
        departLeg = 'LON'; departCoord = {lat: 51.5287718, long: -0.2416814};
      }
      else if (departName === 'Manchester') {
        departLeg = 'MAN'; departCoord = {lat: 53.3588026, long: -2.2727303};
      }
      else if (departName === 'Birmingham') {
        departLeg = 'BHX'; departCoord = {lat: 52.4523821, long: -1.7435071};
      }
      else if (departName === 'Newcastle') {
        departLeg = 'NCL'; departCoord = {lat: 55.0374985, long: -1.6916699};
      }
      else if (departName === 'Edinburgh') {
        departLeg = 'EDI'; departCoord = {lat: 55.9500008, long: -3.3724999};
      }
      else if (departName === 'Glasgow') {
        departLeg = 'GLA'; departCoord = {lat: 55.8718987, long: -4.4330602};
      }
      this.setDepartLeg(departLeg, departCoord);
    }
  }

  handleDestSelectChange = (destToInsert, insertBeforeIndex) => {
    this.setState({
      noDestinationSelectedError: false
    })
    var savedTripLegs = this.state.savedTripLegs
    if (insertBeforeIndex !== null) {
      var newArray = [];
      savedTripLegs.forEach((destIterate) => {
        if (destIterate !== 'destinationDropdown') {
          newArray.push(destIterate)
        }
        else {
          newArray.push(destToInsert)
        }
      })
      savedTripLegs = newArray
    }
    else {
      savedTripLegs.push(destToInsert)
    }
    // Add point to the map by IATA.
    this.addPoint(destToInsert.value)

    this.setState({
      destinationDropdownVisible: false,
      destinationNextLinkHidden: false,
      savedTripLegs: savedTripLegs
    })
  }

  removeLeg = (indexToRemove) => {
    var newArray = [];
    var index = 0
    this.state.savedTripLegs.forEach((dest) => {
      if (index !== indexToRemove) {
        newArray.push(dest)
      }
      else {
        // Remove point from the map by IATA.
        this.removePointByIata(dest.value);
      }
      index++
    })
    this.setState({
      savedTripLegs: newArray
    })
    if (newArray.length === 0) {
      this.setState({
        destinationDropdownVisible: true
      })
    }
  }

  handleDatepickerStartChange = date => {
    this.setState({
      startDate: date,
    })
  }

  handleDatepickerEndChange = date => {
    this.setState({
      endDate: date,
    })
  }

  handleAddStopLink = () => {
    if (this.state.savedTripLegs.length) {
      this.setState({
        destinationDropdownVisible: true,
        destinationNextLinkHidden: true,
        downshiftValue: ''
      })
    }
  }

  handleSubmit = event => {
    event.preventDefault()

    this.setState({display_loading_gif: true});
    scroller.scrollTo('enquiry-form-anchor', {
      duration: 500,
      smooth: true,
      offset: -20
    })
    
    var enquiryUrl = `https://data.fclmedia.com/sendEnquiry?departName=${encodeURIComponent(this.state.departName)}&departDate=${encodeURIComponent(formatDate(new Date(this.state.startDate)))}&returnDate=${encodeURIComponent(formatDate(new Date(this.state.endDate)))}&quoteText=${encodeURIComponent(this.state.quoteText)}&title=${encodeURIComponent(this.state.title)}&firstName=${encodeURIComponent(this.state.firstName)}&surname=${encodeURIComponent(this.state.surname)}&phone=${encodeURIComponent(this.state.phone)}&email=${encodeURIComponent(this.state.email)}&call_time=${encodeURIComponent(this.state.call_time)}&${this.state.call_time}=1&newsletter=${encodeURIComponent(this.state.newsletter)}&hnpt=TRAVEL&keyword=${encodeURIComponent(this.state.keyword)}&eCommerceProdName=${encodeURIComponent(this.state.eCommerceProdName)}&form_id=${encodeURIComponent(this.state.form_id)}&todaysDate=${encodeURIComponent(getCurrentDate())}&utm_source=${encodeURIComponent(this.state.utm_source)}&utm_medium=${encodeURIComponent(this.state.utm_medium)}&utm_campaign=${encodeURIComponent(this.state.utm_campaign)}&routing_code=${encodeURIComponent(this.state.routing_code)}&pageUrl=${encodeURIComponent(this.state.pageUrl)}&country=UK&brand=${encodeURIComponent(this.state.brand)}&consultantEmailTemplate=uk%2Ffc%2Fct-message-tripPlanner.ftl&bounceBackEmailTemplate=uk%2Ffc%2Fbb-message-tripPlanner.ftl&consultantSubjectTemplate=uk%2Ffc%2Fct-subject-journeyPlanner.ftl&failUrl=%2Fcontact-us%2Ffailure&forwardUrl=%2Fcontact-us%2Fsuccess&validationTemplate=uk%2Ffc%2FbasicHoneypotValidate.ftl&bounceBackSubjectTemplate=uk%2Ffc%2Fbb-subject-journeyPlanner.ftl&enquiryCategory=${encodeURIComponent(this.state.enquiryCategory)}&productEnquire=no&js=true`
    enquiryUrl += `&routeLegs=`
    var route_leg_array_params = ''
    var savedRoute_param = ''
    var index = 0
    this.state.savedTripLegs.forEach((dest) => {
      route_leg_array_params += `&route_legs%5B%5D=` + dest.value;
      enquiryUrl += dest.value;
      var destLabel = dest.label;
      var stopOrdinal = index + 1;
      savedRoute_param += stopOrdinal + ') ' + destLabel.replace(/,/g, '%2C') + ' | ';
      if (index < this.state.savedTripLegs.length-1) {
        enquiryUrl += '+%7C+';
      }
      index++;
    })

    enquiryUrl += '&savedRoute=' + encodeURIComponent(savedRoute_param);
    enquiryUrl += route_leg_array_params

    var departureDate = formatDate(new Date(this.state.startDate));
    var departureDateArr = departureDate.split('/');
    var departureDay = parseInt(parseInt(departureDateArr[0]), 10);
    var departureMonth = parseInt(parseInt(departureDateArr[1]), 10);
    var departureYear = departureDateArr[2];
    enquiryUrl += '&departureMonth=' + encodeURIComponent(departureMonth + "/" + departureYear);
    enquiryUrl += '&departureDay=' + departureDay;

    var returnDate = formatDate(new Date(this.state.endDate));
    var returnDateArr = returnDate.split('/');
    var returnDay = parseInt(parseInt(returnDateArr[0]), 10);
    var returnMonth = parseInt(parseInt(returnDateArr[1]), 10);
    var returnYear = returnDateArr[2];
    enquiryUrl += '&returnMonth=' + encodeURIComponent(returnMonth + "/" + returnYear);
    enquiryUrl += '&returnDay=' + returnDay;

    if (this.state.savedTripLegs.length === 0) {
      this.setState({
        noDestinationSelectedError: true
      })
      scroller.scrollTo('enquiry-form-anchor', {
        duration: 500,
        smooth: true
      })
    }
    else if (!this.isValidPhoneNumber(this.state.phone)) {
      document.getElementById('phoneNumberAlert').style.display = 'block';
    }
    else {
      document.getElementById('phoneNumberAlert').style.display = 'none';

      fetch(enquiryUrl, {
        method: 'GET'
      })//.then((res) => res.json())
      .then((response) =>  {
        let success = true;
        if (success) {
          this.setState({formError_tp: false})
          this.setState({formSuccess_tp: true})
          this.setState({display_loading_gif: false});
          successMsgBoxRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }
        else {
          console.log(response);
          this.setState({formError: true})
          this.setState({formSuccess: false})
          this.setState({display_loading_gif: false});
        }
      }).catch((err) => {
        this.setState({formError_tp: false})
        this.setState({formSuccess_tp: true})
        this.setState({display_loading_gif: false});
        successMsgBoxRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
        console.log(err);
      });
    }
  }

  sortableOnChange = (newIndexes) => {
    var newArray = []
    var iataArray = [];
    while (newIndexes.length) {
      var nextOrder = parseInt(newIndexes[0]);
      for (var index = 0; index < this.state.savedTripLegs.length; index++ ) {
        var dest = this.state.savedTripLegs[index];
        if (nextOrder === index) {
          newArray.push(dest)
          iataArray.push(dest.value)
        }
      }
      newIndexes.shift();
    }
    
    // Rebuild map points by the reordered IATA array.
    this.rebuildByIataArray(iataArray);

    this.setState({
      savedTripLegs: newArray
    })
  }

  handleTripLegClick = (clickedIndex) => {
    var newArray = [];
    var index = 0
    this.state.savedTripLegs.forEach((dest) => {
      if (index === clickedIndex) {
        newArray.push('destinationDropdown')
        // Remove point from the map by IATA.
        this.removePointByIata(dest.value);
      }
      else {
        newArray.push(dest)
      }
      index++
    })
    this.setState({
      savedTripLegs: newArray,
      destinationNextLinkHidden: true,
      destinationDropdownVisible: false,
      downshiftValue: ''
    })
  }

  handleDownshiftStateChange = (changes, downshiftState) => {
    if (changes.hasOwnProperty('inputValue')) {
      this.setState({
        downshiftValue: changes.inputValue
      })
    }

    if (changes.hasOwnProperty('isOpen')) {
      var isOpen = changes.type === Downshift.stateChangeTypes.mouseUp ? isOpen : changes.isOpen
      //console.log(isOpen)
    }
  }

  handleMapLoaded = (map, maps) => {
    this.map = map
    this.maps = maps
    map.setMapTypeId(maps.MapTypeId.TERRAIN);
  }

  getMapOptions = () => {

    return {
      backgroundColor: "#F7F5F2",
      zoom: 1,
      minZoom: 1,
      //center: maps.LatLng(0, 0),
      //mapTypeId: maps.MapTypeId.TERRAIN,
      panControl: false,
      gestureHandling: "cooperative",
      disableDoubleClickZoom: false,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      overviewMapControl: true,
      fullscreenControl: false,
      zoomControl: true,
      draggable: true,
      styles: [
        {
          "featureType": "administrative",
          "elementType": "all",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "administrative.country",
          "elementType": "geometry.stroke",
          "stylers": [
            {
              "visibility": "on"
            },
            {
              "color": "#F7F5F2"
            },
            {
              "weight": 0.8
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "all",
          "stylers": [
            {
              "color": "#F7F5F2"
            }
          ]
        },
        {
          "featureType": "landscape",
          "elementType": "all",
          "stylers": [
            {
              "color": "#DDD4CB"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "all",
          "stylers": [
            {
              "color": "#DDD4CB"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#515255"
            },
            {
              "weight": 0.6
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "all",
          "stylers": [
            {
              "visibility": "on"
            }
          ]
        },
        {
          "featureType": "transit",
          "elementType": "all",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "all",
          "elementType": "labels",
          "stylers": [
            {
              "visibility": "on"
            }
          ]
        }
      ]
    };
  }

  map = null
  maps = null
  journeyPath = []
  markers = []
  all_points = []
  iataOrderArray = []
  departIata = undefined

  refreshPolyline = () => {
    const this_ = this
    if (this_.journeyPath !== undefined) {
      for (var i = 0; i < this_.journeyPath.length; i++) {
        this_.journeyPath[i].setMap(null);
      }
    }
    this_.journeyPath = [];
    if (this_.markers !== undefined) {
      for (i = 0; i < this_.markers.length; i++) {
        this_.markers[i].setMap(null);
      }
    }
    
    this_.markers = [];
    var all_points = this.all_points;
    var journeyRouteCoordinates = [];
    var bounds = new this_.maps.LatLngBounds();
    var index = 0;
    this.iataOrderArray.forEach(function(iata){
      var value = all_points[iata];
      var strokeColour = '#3A8986',
        strokeOpacity = 1.0,
        lineSymbol = {
          path: this_.maps.SymbolPath.FORWARD_OPEN_ARROW,
          scale: 2.5
        },
        iconSettings = {
          icon: lineSymbol,
          offset: '60%'
        };

      // Define markers.
      this_.markers[index] = new this_.maps.Marker({
        position: new this_.maps.LatLng(value['lat'], value['long']),
        icon: mapMarkerPin,
        map: this_.map
      });

      bounds.extend(this_.markers[index].position);

      // Add lat long pairs to array.
      var stops = new this_.maps.LatLng(value['lat'], value['long']);
      journeyRouteCoordinates.push(stops);
      // Keep the latest 2 pairs.
      if (journeyRouteCoordinates.length > 2) {
        journeyRouteCoordinates.shift();
      }

      this_.journeyPath.push(new this_.maps.Polyline({
        path: journeyRouteCoordinates,
        geodesic: true,
        strokeColor: strokeColour,
        strokeOpacity: strokeOpacity,
        icons: [iconSettings],
        strokeWeight: 2,
        map: this_.map
      }));

      index++;
    });

    this_.map.fitBounds(bounds);

    if (Object.keys(all_points).length === 0) {
      this_.map.setZoom(1);
    }
  }

  addPoint = (iata) => {
    if (typeof window === `undefined` || window.innerWidth < 530) {
      // We don't want to use the map on mobile.
      return;
    }
    const _maps = this.maps;
    var _iataOrderArray = this.iataOrderArray;
    const this_ = this;
    var location_found = null;
    this.destinations.forEach(obj => {
      if (obj.iata === iata && obj.location !== undefined && obj.location !== '') {
        location_found = obj.location;
      }
    });
    if (location_found !== null) {
      var inputAddress = location_found;
      var geocoder = new _maps.Geocoder();
      geocoder.geocode({
        "address": inputAddress
      }, function(results) {
        if (results[0].geometry.location !== undefined) {
          var lat = results[0].geometry.location.lat();
          var lng = results[0].geometry.location.lng();
          var coord = {lat: lat, long: lng};
          var all_points = this_.all_points;
          if (_iataOrderArray === undefined) {
            _iataOrderArray = [];
          }
          _iataOrderArray.push(iata);
          all_points[iata] = coord;
          this_.refreshPolyline();
        }
      });
    }
  }

  removePointByIata = (iata) => {
    var all_points = this.all_points;
    delete all_points[iata];
    var newIataOrderArray = [];
    for (var i = 0; i < this.iataOrderArray.length; i++) {
      if (this.iataOrderArray[i] !== iata) {
        newIataOrderArray.push(this.iataOrderArray[i]);
      }
    }
    this.iataOrderArray = newIataOrderArray;
    this.refreshPolyline();
  }

  rebuildByIataArray = (iataArray) => {
    if (this.departIata !== undefined) {
      iataArray.unshift(this.departIata);
    }
    this.iataOrderArray = iataArray;
    this.refreshPolyline();
  }

  setDepartLeg = (departIata, departCoord) => {
    if (this.departIata !== undefined) {
      this.iataOrderArray.shift();
    }

    if (this.iataOrderArray === undefined) {
      this.iataOrderArray = [];
    }
    this.iataOrderArray.unshift(departIata);
    this.all_points[departIata] = departCoord;

    this.refreshPolyline();
    this.departIata = departIata
  }

  render() {
    const successMessageDisplay = this.state.formSuccess_tp ? 'block' : 'none';
    const errorMessageDisplay = this.state.formError_tp ? 'block' : 'none';
    const inputFormDisplay = this.state.formSuccess_tp || this.state.formError_tp ? 'none' : 'block';
    const destinationDropdownDisplay = this.state.destinationDropdownVisible ? 'inline-block' : 'none';
    const destinationNextLinkDisabledClass = this.state.savedTripLegs.length ? '' : ' disabled';
    const destinationNextLinkHiddenClass = this.state.destinationNextLinkHidden ? ' is-hidden' : '';
    const noDestinationSelectedErrorClass = this.state.noDestinationSelectedError ? ' error' : '';

    const listItems = this.state.savedTripLegs.map((dest, index) => {
      if (dest === 'destinationDropdown') {
        return (
          <li key={`tripleg_${dest.value}_${index}`} data-id={index} className={`leg arrow-prefix`} style={{zIndex: 5}}>
            <Downshift
              onChange={selection => selection && this.handleDestSelectChange(selection, index)}
              itemToString={item => (item ? item.label : '')}
              inputValue = {this.state.downshiftValue}
              onStateChange ={this.handleDownshiftStateChange}
              selectedItem  = {''}
              >
              {({
                getInputProps,
                getItemProps,
                getMenuProps,
                isOpen,
                inputValue,
                highlightedIndex,
                selectedItem,
              }) => (
                <div className='downshiftWrapper'>
                  <input className='downshiftInput input' placeholder={'Next destination *'} {...getInputProps()} />
                  <ul className={isOpen ? 'isOpen' : ''} {...getMenuProps()}>
                  {isOpen
                    ? selectOptions
                      .filter(item => !inputValue || item.label.toLowerCase().includes(inputValue.toLowerCase()))
                      .map((item, index) => (
                        <li
                        {...getItemProps({
                          key: `${item.value}${index}`,
                          index,
                          item,
                          className: highlightedIndex === index ? 'highlighted' : '',
                        })}
                        >
                          {item.label}
                        </li>
                        ))
                    : null}
                  </ul>
                </div>
              )}
            </Downshift>
          </li>
        )
      }
      else {
        return (
        <li key={`tripleg_${dest.value}_${index}`} data-id={index} className={`leg arrow-prefix`}>
          <div className="leg-container">
            <span className="legTag">To</span>
            <span className="route-leg-name" onClick={this.handleTripLegClick.bind(this, index)}>{dest.label}</span>
            <a className="edit-leg">edit</a>
            <a className="remove-leg" onClick={this.removeLeg.bind(this, index)}>X</a>
          </div>
        </li>
        )
      }
    });

    return (
    <>
      <div ref={successMsgBoxRef} className='trip-planner-form-success-box' style={{display: successMessageDisplay}}>
        <p>
          Thank you for submitting your itinerary.<br/>
          We'll be in contact shortly to help arrange your trip.
        </p>

        <div className="destination_holder">
          <div>{this.state.departName}</div>

          { this.state.formSuccess_tp && this.state.savedTripLegs.map((dest) => {
            var splitted_label = dest.label.split(' (');
            splitted_label.pop();
            return (
              <div key={`success_msg_leg_key_${dest.value}`}>{splitted_label}&nbsp;&nbsp;<span>{dest.value}</span></div>
            )
          })}

        </div>

        <p>You'll be on your way before you know it.</p>
      </div>
      <div style={{display: errorMessageDisplay}}>
        <p style={{ textAlign: "center" }} className="is-size-5 has-text-danger">
          <br/>
          An unexpected error occurred, please try again.
        </p>
      </div>

      {
      !this.state.display_loading_gif &&
      <form className="trip-planner-form" nls_fa_name="Round The World - General" action="" method="GET" acceptCharset="UTF-8" style={{display: inputFormDisplay}} onSubmit={this.handleSubmit}>

        <div className="drag_n_drop_cols">
          <div className="col is-4 departureWrapper">
            <div className="field">
              <div className="control">
                <div className="select is-fullwidth">
                  <select name="departName" required onChange={this.handleInputChange}>
                    <option value="">Departure from? *</option>
                    <option value="London Heathrow">London</option>
                    <option value="Manchester">Manchester</option>
                    <option value="Birmingham">Birmingham</option>
                    <option value="Newcastle">Newcastle</option>
                    <option value="Edinburgh">Edinburgh</option>
                    <option value="Glasgow">Glasgow</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          { this.state.sortableImported === true && <Sortable
            className={`trip-legs ui-sortable leg_count_${this.state.savedTripLegs.length}`}
            style={{display: this.state.savedTripLegs.length ? 'inline' : 'none'}}
            options={{
            }}
            ref={(c) => {
                if (c) {
                    //sortable = c.sortable;
                }
            }}
            tag="ul"
            onChange={(order, sortable, evt) => {
                this.sortableOnChange(order);
            }}
          >
            {listItems}
          </Sortable> }

          <div className="col is-4 destinationDropdownWrapper arrow-prefix" style={{display: destinationDropdownDisplay}}>
            <div className="field">
              <div className="control">
                <Downshift
                  onChange={selection => selection && this.handleDestSelectChange(selection, null)}
                  itemToString={item => (item ? item.label : '')}
                  inputValue = {this.state.downshiftValue}
                  selectedItem  = {''}
                  onStateChange ={this.handleDownshiftStateChange}
                  >
                  {({
                    getInputProps,
                    getItemProps,
                    getMenuProps,
                    isOpen,
                    inputValue,
                    highlightedIndex,
                    selectedItem,
                  }) => (
                    <div className='downshiftWrapper'>
                      <input className={`downshiftInput input${noDestinationSelectedErrorClass}`} placeholder={'Next destination *'} {...getInputProps()} />
                      <ul className={isOpen ? 'isOpen' : ''} {...getMenuProps()}>
                      {isOpen
                        ? selectOptions
                          .filter(item => !inputValue || item.label.toLowerCase().includes(inputValue.toLowerCase()))
                          .map((item, index) => (
                            <li
                            {...getItemProps({
                              key: `${item.value}${index}`,
                              index,
                              item,
                              className: highlightedIndex === index ? 'highlighted' : '',
                            })}
                            >
                              {item.label}
                            </li>
                            ))
                        : null}
                      </ul>
                    </div>
                  )}
                </Downshift>
              </div>
            </div>
          </div>

          <div className={`col is-4 destination-link-holder${destinationNextLinkHiddenClass}`}>
            <a className={`add-stop-link${destinationNextLinkDisabledClass}`} onClick={this.handleAddStopLink}>+ <i>Add a destination</i></a>
          </div>
          <div className='is-clearfix'></div>
        </div>

        <div className="columns is-multiline detail-fields-wrapper">
          <div className='column is-12 is-8-desktop'>
            <div id="trip-planner-map-canvas" className="trip-planner-map-canvas">
              <GoogleMapReact
                options={this.getMapOptions}
                bootstrapURLKeys={{ key: 'AIzaSyAsudp9ZvU66E7xmYnqX4V4wNOQWonXCnE' }}
                defaultCenter={[0, 0]}
                defaultZoom={4}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => this.handleMapLoaded(map, maps)}
              >                
              </GoogleMapReact>
            </div>
          </div>
          <div className='column is-12 is-4-desktop'>
            <textarea className="textarea" onChange={this.handleInputChange} name="quoteText" placeholder={ "Do you have a preferred airline? Are your travel dates flexible? Do you prefer direct or indirect flights? Do you need anything else – hotel, car hire, transfers, excursions?" }></textarea>
          </div>
        </div>

        <div className='columns'>
          <div className="column is-6">                  
            <div className="field">
              <div className="control">
                <DatePicker
                  selected={this.state.startDate}
                  selectsStart
                  minDate={new Date()}
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  onChange={this.handleDatepickerStartChange}
                  required
                  placeholderText='Departing date    dd/mm/yy *'
                />
              </div>
            </div>
          </div>
          <div className="column is-6">                  
            <div className="field">
              <div className="control">
                <DatePicker
                  selected={this.state.endDate}
                  selectsEnd
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  onChange={this.handleDatepickerEndChange}
                  minDate={this.state.startDate}
                  required
                  placeholderText='Returning date    dd/mm/yy *'
                />
              </div>
            </div>
          </div>
        </div>

        <div className='columns'>
          <div className="column is-narrow cus_title_field">
            <div className="field">
              <div className="control">
                <div className="select is-fullwidth">
                  <select name="title" required onChange={this.handleInputChange}>
                    <option value="">Title *</option>
                    <option value="Mr">Mr</option>
                    <option value="Mrs">Mrs</option>
                    <option value="Miss">Miss</option>
                    <option value="Ms">Ms</option>
                    <option value="Dr">Dr</option>
                  </select>
                </div>
              </div>  
            </div>
          </div>

          <div className="column">
            <div className="field">
              <div className="control">
                <input className="input" type="text" name="firstName" placeholder="First Name *" required onChange={this.handleInputChange} />
              </div>
            </div>
          </div>

          <div className="column">
            <div className="field">
              <div className="control">
                <input className="input" type="text" name="surname" placeholder="Last Name *" required onChange={this.handleInputChange} />
              </div>
            </div>
          </div>
        </div>

        <div className='columns is-multiline'>
          <div className="column is-6">
            <div className="field">
              <div className="control">
                <input className="input" type="email" placeholder="Email *" name="email" required onChange={this.handleInputChange} />
              </div>
            </div>
          </div>

          <div className="column is-6">
            <div className="field">
              <div className="control">
                <PhoneInput country="UK" 
                  className="input" 
                  value={ this.state.phone } 
                  placeholder={ "Phone" + (this.state.category === 'f&b' ? '' : ' *') }
                  onChange={ value => this.setState({ phone: value }) } 
                  required={ this.state.category === 'f&b' ? false : true }
                />
              </div>
            </div>
          </div>

          <div className="column is-6">
            <div className="field">
              <div className="control">
                <div className="select is-fullwidth">
                  <select name="call_time" onChange={this.handleInputChange}>
                    <option value="">When is best to call you?</option>
                    <option value="call_time_morning">Morning (9:00 - 12:00)</option>
                    <option value="call_time_early_afternoon">Early afternoon (12:00 - 15:00)</option>
                    <option value="call_time_late_afternoon">Late afternoon (15:00 - 17:00)</option>
                    <option value="call_time_evening">Evening (17:00 - 20:00)</option>
                  </select>
                </div>
              </div>  
            </div>
          </div>
        </div>

        <div className='columns newsletter-section'>
          <div className='column'>
            <p>While you're here, we'd love to send you our latest {this.state.category === 'holidays' ? 'holiday' : 'travel'} offers and information.
              Tick below to subscribe; you have the right to withdraw your consent from marketing communication at any time *
            </p>
            <br/>

            <div className="control">
              <label className="radio">
                <input type="radio" name="newsletter" value="1" required onChange={this.handleInputChange} />
                &nbsp;
                Yes please, I want to hear about the latest offers from Flight Centre
              </label>
            </div>
            <div className="control">
              <label className="radio">
                <input type="radio" name="newsletter" value="0" required onChange={this.handleInputChange} />
                &nbsp;
                No thank you, I don't want to hear about the latest offers from Flight Centre
              </label>
            </div>

            <p className='privacy'>We value our customers' privacy and will treat your personal details with the utmost care and respect.
              For information about how we handle your data, please read our <a target="_blank" rel="noopener noreferrer" href="https://www.flightcentre.co.uk/policies/privacy-policy">Privacy Policy</a>.
            </p>

            <br/>
            <div className="control is-fullwidth has-text-centered">
              <button type="submit" className="button form-submit-button blue-button">Submit my itinerary</button>
            </div>
          </div>
        </div>

        <input type="hidden" name="keyword" value={this.state.keyword} />
        <input type="hidden" name="eCommerceProdName" value={this.state.eCommerceProdName} />
        <input type="hidden" name="form_id" value={this.state.form_id} />
        <input type="hidden" name="routeLegs" value={this.state.routeLegs} />
        <input type="hidden" name="todaysDate" value={getCurrentDate()} />
        <input type="hidden" name="utm_source" value={this.state.utm_source} />
        <input type="hidden" name="utm_medium" value={this.state.utm_medium} />
        <input type="hidden" name="utm_campaign" value={this.state.utm_campaign} />
        <input type="hidden" name="routing_code" value={this.state.routing_code} />
        <input type="hidden" name="pageUrl" value={this.state.pageUrl} />
        <input type="hidden" name="country" value="UK" />
        <input type="hidden" name="brand" value={this.state.brand} />
        <input type="hidden" name="consultantEmailTemplate" value="uk/fc/ct-message-tripPlanner.ftl" />
        <input type="hidden" name="bounceBackEmailTemplate" value="uk/fc/bb-message-tripPlanner.ftl" />
        <input type="hidden" name="consultantSubjectTemplate" value="uk/fc/ct-subject-journeyPlanner.ftl" />
        <input type="hidden" name="forwardUrl" value="/contact-us/success" />
        <input type="hidden" name="failUrl" value="/contact-us/failure" />
        <input type="hidden" name="validationTemplate" value="uk/fc/basicHoneypotValidate.ftl" />
        <input type="hidden" name="bounceBackSubjectTemplate" value="uk/fc/bb-subject-journeyPlanner.ftl" />
        <input type="hidden" name="enquiryCategory" value={this.state.enquiryCategory} />
        <input type="hidden" name="productEnquire" value="no" />
      </form>
      }

      {
      this.state.display_loading_gif &&
      <img className="form-loading-gif" alt="Loading" src={loadingGif} />
      }

      <div id="phoneNumberAlert" style={{display: 'none'}}>
        <p style={{ textAlign: "center", marginTop: 20 }} className="is-size-6 has-text-danger">
          Please specify a valid UK phone number.
        </p>
      </div>
    </>
    );
  }
}

export default TripPlannerFormContainer;
