import React from "react"
import { scroller } from "react-scroll"

class EmailScroll extends React.Component {
  onGetAQuoteButtonClick = event => {
    scroller.scrollTo('enquiry-form-anchor', {
      duration: 500,
      smooth: true,
      offset: -20
    })
  }

  render() {
    return(
      <>
        <div className='column widget-box email-us' onClick={this.onGetAQuoteButtonClick}>
          <div className="email-scroll-widget"> 
            <p className="text-label-1">Send an Email</p>
            <p className="text-label-2">Message a Consultant</p>
          </div>
        </div>
      </>
    )    
  }
}
export default EmailScroll 
