import React from "react"
import $ from 'jquery'
//import { Link } from "gatsby"
import { graphql } from 'gatsby'
import Img from "gatsby-image"
import SEO from "../components/seo"
import Layout from "../components/layout"
import Trustpilot from "../components/trustpilot"
import CtaWidgets from "../components/cta-widgets"
import CallBackFormWidget from "../components/callback-form-widget"
import EnquiryFormContainer from "../components/enquiry-form"
import TripPlannerFormContainer from "../components/trip-planner-form"
import FlightGrid from "../components/flight-grid"
import FlightPrice from "../components/flight-price"
import FlightGridCTAPopup from "../components/flight-grid-cta-popup"
import { observer, inject } from "mobx-react"
import { Element } from "react-scroll"
import allNoTextSvg from '../images/all_travel_no_worries_text.svg'
import trustPilotLogo from '../images/trustpilot-5star-solid-white.svg'
import "../css/ppc-page.min.css"

function GetFormByCategory(props) {
  if (props.category === 'trip_planner') {
    return <TripPlannerFormContainer category={props.category} />;
  }
  else {
    return <EnquiryFormContainer togglePopupOverlayFunction={props.togglePopupOverlayFunction} destinations={props.destinations} category={props.category} />;
  }
}

@inject('store')
@observer
class Ppcpage extends React.Component {

  closeMobileCallBackPopup = event => {
    $('.fullscreen-overlay').fadeOut();
    $('body,html').css('overflow', 'auto');
  }

  state = {displayPopupOverlay: false}

  togglePopupOverlay = () => {
    this.setState({displayPopupOverlay: !this.state.displayPopupOverlay})
  }

  render() {
    const data = this.props.data
    const store = this.props.store

    return (
      <Layout location={this.props.location}>
        <SEO title={`${data.nodePpcPage.field_hero_title} | Flight Centre UK`} description={`Save On Worldwide Flights`} />

        <section className='hero'>
          <Img className='hero-bg-image' fluid={data.nodePpcPage.relationships.heroImage.localFile.childImageSharp.fluid} />
          <div className='container'>
            <div className='columns is-gapless'>
              <div className='column'>
                <h1 className='title is-1'>
                  {data.nodePpcPage.field_hero_title}
                  {
                  data.nodePpcPage.field_destination.length > 0 &&
                  <FlightPrice destinationTid={data.nodePpcPage.field_destination } />
                  }
                </h1>
                <div className='hero-text' dangerouslySetInnerHTML={{ __html: data.nodePpcPage.field_hero_text }}></div>
                <img className='trustpilot' alt="TrustPilot" src={trustPilotLogo} />
              </div>
            </div>
            <CtaWidgets category={data.nodePpcPage.field_page_category} />
          </div>
        </section>

        <section className="section all-no-logo">
          <div className="container has-text-centered">
            <img alt="All travel no worries" src={allNoTextSvg} />
          </div>
        </section>

        <section className="section quality-score">
          <div className="container has-text-centered">
            <div className="columns">
              <div className="column">
                <h2 className="title is-2">{ data.nodePpcPage.field_heading_quality_score }</h2>
                <div dangerouslySetInnerHTML={{ __html: data.nodePpcPage.field_text_quality_score.processed }}></div>
              </div>
            </div>
          </div>
        </section>
        
        {
        data.nodePpcPage.field_destination.length > 0 &&
        <section className="section flight-grid">
          <FlightGrid destination_id={data.nodePpcPage.field_destination} category={data.nodePpcPage.field_page_category}/>
        </section>
        }

        <FlightGridCTAPopup category={data.nodePpcPage.field_page_category}/>

        <section className={`section form-section-anchor ${data.nodePpcPage.field_page_category === 'trip_planner' ? 'trip-planner-form': 'enquiry-form'}`}>
          <Element name="enquiry-form-anchor"></Element>
          <div className="container">
            <h2 className="title is-2 has-text-centered">{ data.nodePpcPage.field_heading_form }</h2>
            <GetFormByCategory togglePopupOverlayFunction={this.togglePopupOverlay} category={data.nodePpcPage.field_page_category} destinations={data.nodePpcPage.field_page_category === 'trip_planner' ? null : data.destinations} />
          </div>
        </section>

        <section className="section trust-pilot">
          <div className="container">
            <div className="columns">
              <div className="column">
                <h2 className="title is-2 has-text-centered">{ data.nodePpcPage.field_heading_trust_pilot }</h2>
                <Trustpilot />
              </div>
            </div>
          </div>
        </section>

        <div className="fullscreen-overlay is-hidden-desktop">
          <div className="close-x" onClick={this.closeMobileCallBackPopup}>Close X</div>
          <div className="inner">
            <CallBackFormWidget category={data.nodePpcPage.field_page_category} />
          </div>
        </div>
        
        {this.state.displayPopupOverlay && <div className="dark-popup-overlay"></div>}
      </Layout>
    )
  }
}

export default Ppcpage

export const query = graphql `
  query nodePpcPage($id: String) {
    destinations: allDestinationsJson {
      edges {
        node {
          realLabel
          realValue
        }
      }
    }
    nodePpcPage(id: { eq: $id }) {
      title
      field_hero_title
      field_hero_text
      field_heading_quality_score
      field_text_quality_score  {
        processed
      }
      field_heading_form
      field_heading_trust_pilot
      field_page_category
      field_destination

      relationships {
        heroImage: field_background_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1440, srcSetBreakpoints: [ 484, 996, 1284, 1440, 1920 ]) {
                src
                srcSet
                sizes
                aspectRatio
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
