import React, { Component } from "react";
import $ from 'jquery'
import PhoneInput from "react-phone-number-input/basic-input"
//import { isValidPhoneNumber } from 'react-phone-number-input'
import iconTick from '../images/icon-tick.svg'

class CallBackFormWidget extends Component {
  state = {targetNumber: '', serviceID: null};

  componentDidMount() {
    const { category } = this.props;
    this.setState({
      serviceID: category === 'f&b' ? 6251 : 6020
    });
  }

  handleCallbackTryAgainClick() {
    $('.callback-success-msg').hide();
    $('.fullscreen-overlay .call-back-form').show();
  }

  isValidPhoneNumber(phone_number) {
    if (phone_number.length < 11) {
      return false;
    }
    else {
      if (phone_number.charAt(0) !== "0") {
        return false;
      }
    }
    return true;
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value,
    })
  }

  handleSubmit = event => {
    event.preventDefault()

    if (!this.isValidPhoneNumber(this.state.targetNumber)) {
      if ($('.fullscreen-overlay').css('display') === 'block') {
        $('.fullscreen-overlay .phoneNumberAlert').show();
      }
      else {
        $('.hero .phoneNumberAlert').show();
      }
    }
    else {
      $('.phoneNumberAlert').hide();

      fetch(`https://www.flightcentre.co.uk/api/fcluk_sesui/ctc?form_id=click-to-call&serviceID=${this.state.serviceID}&httpInitNoPIN=y&failURL=${encodeURIComponent('https://www.flightcentre.co.uk')}&successURL=${encodeURIComponent('https://www.flightcentre.co.uk')}&currentURL=${encodeURIComponent(window.location.href)}&targetnumber=${encodeURIComponent(this.state.targetNumber)}&js=true`, {
        method: 'GET'
      }).then((res) => res.json())
      .then((response) =>  {
        let success = true;

        if (typeof response.error !== typeof undefined && response.error === 1) {
          success = false;
        }
        if (typeof response.status !== typeof undefined && response.status !== 200) {
          success = false;
        }
        if (success && (typeof response.result.code === typeof undefined || response.result.code !== 200)) {
          success = false;
        }
        if (success && (typeof response.result.data === typeof undefined || parseInt(response.result.data.substring(0, 3)) !== 200)) {
          success = false;
        }
        if (success) {
          if ($('.fullscreen-overlay').css('display') === 'block') {
            $('.fullscreen-overlay .call-back-form').hide();
            $('.fullscreen-overlay .callback-success-msg').show();
          }
          else {
            $('.hero .callback-success-msg').show();
          }
        }
        else {
          console.log(response);
          if ($('.fullscreen-overlay').css('display') === 'block') {
            $('.fullscreen-overlay .call-back-form').hide();
            $('.fullscreen-overlay .callback-error-msg').show();
          }
          else {
            $('.hero .callback-error-msg').show();
          }
        }
      }).catch((err) => console.log(err));
    }
  }

  render() {
    return (
    <>
      <div className="callback-success-msg" style={{display: 'none'}}>
        <h3 className='title is-3'>We’re about to call!</h3>
        <div className='icon'><img alt="Call Us - Free" src={iconTick} /></div>
        didn’t work? <button onClick={this.handleCallbackTryAgainClick}>Try again</button>
      </div>
      <div className="callback-error-msg" style={{display: 'none'}}>
        <h3 className='title is-3'>Oh no, its not working. <span>!</span></h3>
        <p>Sorry it doesn’t seem to be working.
        Try another contact method.</p>
      </div>
      <form className="call-back-form" nls_fa_name="forms/ctc" action="" method="GET" acceptCharset="UTF-8" onSubmit={this.handleSubmit}>
        <div className="columns is-mobile">
          <div className="column">
            <div className="control">
              <PhoneInput country="UK" 
              className="input" 
              value={ this.state.targetNumber } 
              placeholder="Enter your number" 
              onChange={ value => this.setState({ targetNumber: value }) } required />
              <div className="phoneNumberAlert" style={{display: 'none'}}>
                <span>!</span> Please enter a valid UK number
              </div>
            </div>
          </div>
          <div className="column is-narrow">
            <div className="control">
              <button type="submit" className="button blue-button">Call me<span></span></button>
            </div>
          </div>
        </div>
        <p className='is-hidden-desktop'>We’ll call you back instantly</p>
      </form>
    </>
    );
  }
}

export default CallBackFormWidget;
