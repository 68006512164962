import React, { Component } from "react";
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import loadingGif from '../images/loading-large.gif'
//import "react-datepicker/dist/react-datepicker.css";
import PhoneInput from "react-phone-number-input/basic-input"
//import { isValidPhoneNumber } from 'react-phone-number-input'
import Reaptcha from 'reaptcha';
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-v3'
import { scroller } from "react-scroll"

function processDestOptions(destinations) {
  const selectOptions = [];
  destinations.edges.forEach(element => {
    selectOptions.push({ value: element.node.realValue, label: element.node.realLabel });
  });
  return selectOptions;
}

function formatDate(tempDate) {
  var formatTwoDigits = (digit) => ("0" + digit).slice(-2);
  var date = `${formatTwoDigits(tempDate.getDate())}/${formatTwoDigits(tempDate.getMonth()+1)}/${tempDate.getFullYear()}`;
  return date
}

function getCurrentDate() {
  return formatDate(new Date());
}

// Tracking helper js.
function setCookie(name, value, days){
  var date = new Date();
  date.setTime(date.getTime() + (days*864E5));
  var expires = "; expires=" + date.toGMTString();
  document.cookie = name + "=" + value + expires;
}
function getParam(p){
  if (typeof window !== `undefined`) {
    var match = RegExp('[?&]' + p + '=([^&]*)').exec(window.location.search);
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
  }
  return null;
}

function readCookie(name) {
  if (typeof document !== `undefined`) {
    var n = name + "=";
    var cookie = document.cookie.split(';');
    for(var i=0;i < cookie.length;i++) {
      var c = cookie[i];
      while (c.charAt(0) === ' '){c = c.substring(1,c.length);}
      if (c.indexOf(n) === 0){
        return c.substring(n.length,c.length);
      }
    }
  }
  return null;
}

class EnquiryFormContainer extends Component {
  state = {
    phone: '', 
    formSuccess: false, 
    formError: false, 
    phoneNumberAlert: false,
    destName: '', 
    startDate: null, 
    endDate: null, 
    pageUrl: '',
    utm_source: '',
    utm_medium: '',
    utm_campaign: '',
    msclkid: '',
    gclid: '',
    category: null,
    newsletter: null,
    eCommerceProdName: 'holidays',
    form_id: 'holidays-enquiry-general-image',
    routing_code: 'ukfc.holidays',
    enquiryCategory: 'Holiday Package - General',
    brand: 'FCUK',
    display_loading_gif: false,
    captchaReady: false,
    displayCaptchaV2Popup: false,
    captchaVerified: false,
    captchaRendered: false,
    displayCanceledCaptchaMessage: false
  };

  constructor(props) {
    super(props);

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  captcha = null
  reCaptchaV3Token = null
  recaptchaV3SiteKey = '6Lc2rssUAAAAALwdUhViznp3av5x_S0r73pKuuvr'
  recaptchaV2SiteKey = '6LeD98sUAAAAAPntyEgUcufeCU0TcyVtCmL1bKkn'

  // For reCaptcha V2.
  recaptchaOnLoad = () => {
    this.setState({
      captchaReady: true
    });
  };

  // For reCaptcha V2.
  recaptchaOnVerify = recaptchaResponse => {
    this.setState({
      captchaVerified: true
    });
  };

  // For reCaptcha V2.
  recaptchaOnRender = () => {
    this.setState({
      captchaRendered: true
    });
  }

  renderCaptchaV2 = () => {
    if (!this.state.captchaRendered) {
      // Render the reCaptcha V2 widget.
      this.captcha.renderExplicitly()
    }
    // Display dark overlay.
    this.props.togglePopupOverlayFunction()
    // Display the parent div as popup.
    this.setState({
      displayCaptchaV2Popup: true,
      displayCanceledCaptchaMessage: false
    })
  }

  closeCaptchaPopup = () => {
    // Remove dark overlay.
    this.props.togglePopupOverlayFunction()
    // Close the popup.
    this.setState({
      displayCaptchaV2Popup: false,
      displayCanceledCaptchaMessage: true
    })
  }

  handleClickOutside(event) {
    if (this.state.displayCaptchaV2Popup && this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.closeCaptchaPopup()
    }
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  // For reCaptcha v3.
  verifyCallback = (recaptchaToken) => {
    // Here you will get the final recaptchaToken!!!  
    console.log(recaptchaToken, "<= your recaptcha token")
    this.reCaptchaV3Token = recaptchaToken
  }

  componentDidMount() {
    const { category } = this.props;
    this.setState({
      category: category
    });
    if (category === 'f&b') {
      this.setState({
        eCommerceProdName: 'General F&amp;B',
        form_id: 'fb-enquiry-general-paragraph',
        routing_code: '',
        enquiryCategory: 'F&amp;B Enquiry - General',
        brand: 'FBCUK'
      });
    }
    var url = window.location.search
    var query = url.substr(1);
    var urlParams = {};
    query.split("&").forEach(function(part) {
      var item = part.split("=");
      urlParams[item[0]] = decodeURIComponent(item[1]);
    });
    this.setState({
      pageUrl: window.location.href,
      utm_source: urlParams['utm_source'] !== undefined ? urlParams['utm_source'] : '',
      utm_medium: urlParams['utm_medium'] !== undefined ? urlParams['utm_medium'] : '',
      utm_campaign: urlParams['utm_campaign'] !== undefined ? urlParams['utm_campaign'] : ''
    })

    var msclkid = getParam('msclkid');
    if (msclkid) {
      setCookie('msclkid', msclkid, 90);
    }

    var gclid = getParam('gclid')
    if (gclid) {
      var gclsrcParam = getParam('gclsrc');
      var isGclsrcValid = !gclsrcParam || gclsrcParam.indexOf('aw') !== -1;
      if (gclid && isGclsrcValid) {
        setCookie('gclid', gclid, 90);
      }
    }
    let msclkid_val = readCookie('msclkid')
    let gclid_val = readCookie('gclid')
    this.setState({
      msclkid: msclkid_val ? msclkid_val : '',
      gclid: gclid_val ? gclid_val : ''
    })

    document.addEventListener('mousedown', this.handleClickOutside);

    // For reCaptcha v3.
    loadReCaptcha(this.recaptchaV3SiteKey);
  }

  isValidPhoneNumber(phone_number) {
    if (this.state.category === 'f&b') {
      return true;
    }
    if (phone_number.length < 11) {
      return false;
    }
    else {
      if (phone_number.charAt(0) !== "0") {
        return false;
      }
    }
    return true;
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    const { category } = this.props

    this.setState({
      [name]: value,
    })

    if (name === 'flight_class') {
      //console.log('travel class select changed.')
      var new_routing_code = ''
      var new_brand = ''
      if (category === 'flights') {
        if (value === 'economy') {
          new_routing_code = ''
          new_brand = 'FCUK'
        }
        else {
          new_routing_code = 'ukfc.fbcquote'
          new_brand = 'FBCUK'
        }
      }
      else if (category === 'f&b') {
        if (value === 'economy') {
          new_routing_code = ''
          new_brand = 'FCUK'
        }
        else {
          new_routing_code = 'ukfc.fbcquote'
          new_brand = 'FBCUK'
        }
      }
      else if (category === 'holidays') {
        new_brand = 'FCUK'
        if (value === 'economy') {
          new_routing_code = 'ukfc.rtweuk'
        }
        else {
          new_routing_code = 'ukfc.fbcquote'
        }
      }
      this.setState({
        routing_code: new_routing_code,
        brand: new_brand
      });
    }
  }

  handleDestSelectChange = dest => {
    this.setState({
      destName: dest,
    })
  }

  handleDatepickerStartChange = date => {
    this.setState({
      startDate: date,
    })
  }

  handleDatepickerEndChange = date => {
    this.setState({
      endDate: date,
    })
  }

  handleSubmit = event => {
    event.preventDefault()

    if (!this.isValidPhoneNumber(this.state.phone)) {
      this.setState({phoneNumberAlert: true})
    }
    else {
      this.setState({phoneNumberAlert: false})

      if (!this.reCaptchaV3Token) {
        this.renderCaptchaV2()
      }
      else {
        // Verify the token with our backend.
        fetch('https://live-fcl-site-fcuk.pantheonsite.io/recaptcha_token_verify/v3?token=' + this.reCaptchaV3Token, {
          method: 'GET'
        }).then((res) => res.json())
        .then((res) =>  {
          if (parseFloat(res.score) > 0.8) { 
            this.submitFormRequest()
          }
          else {
            this.renderCaptchaV2()
          }
        }).catch((err) => {
          console.log(err);
        });
      }
    }
  }

  submitFormRequest() {
    if (this.state.displayCaptchaV2Popup) {
      this.closeCaptchaPopup()
    }
    const enquiryUrl = `https://data.fclmedia.com/sendEnquiry?hnpt=TRAVEL&keyword=&eCommerceProdName=${encodeURIComponent(this.state.eCommerceProdName)}&form_id=${encodeURIComponent(this.state.form_id)}&destinationCountry=&utm_source=${encodeURIComponent(this.state.utm_source)}&utm_medium=${encodeURIComponent(this.state.utm_medium)}&utm_campaign=${encodeURIComponent(this.state.utm_campaign)}&routing_code=${encodeURIComponent(this.state.routing_code)}&msclkid=${encodeURIComponent(this.state.msclkid)}&gclid=${encodeURIComponent(this.state.gclid)}&departName=${encodeURIComponent(this.state.departName)}&destName=${encodeURIComponent(this.state.destName.value)}&departDate=${encodeURIComponent(formatDate(new Date(this.state.startDate)))}&returnDate=${encodeURIComponent(formatDate(new Date(this.state.endDate)))}&flight_class=${encodeURIComponent(this.state.flight_class)}&numTravel=${encodeURIComponent(this.state.numTravel)}&quoteText=${encodeURIComponent(this.state.quoteText)}&cus_title=${encodeURIComponent(this.state.cus_title)}&firstName=${encodeURIComponent(this.state.firstName)}&surname=${encodeURIComponent(this.state.surname)}&phone=${encodeURIComponent(this.state.phone)}&email=${encodeURIComponent(this.state.email)}&callbackTime=${this.state.callbackTime}&newsletter=${encodeURIComponent(this.state.newsletter)}&bounceBackEmailTemplate=uk%2Ffc%2Fbb-message-enquiry.ftl&todaysDate=${encodeURIComponent(getCurrentDate())}&consultantEmailTemplate=uk%2Ffc%2Fct-message-enquiry.ftl&enquiryCategory=${encodeURIComponent(this.state.enquiryCategory)}&brand=${encodeURIComponent(this.state.brand)}&pageUrl=${encodeURIComponent(this.state.pageUrl)}&country=UK&validationTemplate=uk%2Ffc%2FbasicHoneypotValidate.ftl&failUrl=%2Fcontact-us%2Ffailure&forwardUrl=%2Fcontact-us%2Fsuccess&productEnquire=no&js=true`

    this.setState({display_loading_gif: true});
    scroller.scrollTo('enquiry-form-anchor', {
      duration: 500,
      smooth: true,
      offset: -20
    })

    fetch(enquiryUrl, {
      method: 'GET'
    })//.then((res) => res.json())
    .then((response) =>  {
      let success = true;
      if (success) {
        this.setState({formError: false})
        this.setState({formSuccess: true})
        this.setState({display_loading_gif: false});
      }
      else {
        console.log(response);
        this.setState({formError: true})
        this.setState({formSuccess: false})
        this.setState({display_loading_gif: false});
      }
    }).catch((err) => {
      this.setState({formError: false})
      this.setState({formSuccess: true})
      this.setState({display_loading_gif: false});
      console.log(err);
    });
  }

  render() {
    const { destinations } = this.props;
    const successMessageDisplay = this.state.formSuccess ? 'block' : 'none';
    const errorMessageDisplay = this.state.formError ? 'block' : 'none';
    const inputFormDisplay = this.state.formSuccess || this.state.formError ? 'none' : 'block';
    return (
    <>
      {
        this.state.newsletter &&
        <ReCaptcha
          sitekey={this.recaptchaV3SiteKey}
          action='ppc_enquiry'
          verifyCallback={this.verifyCallback}
        />
      }
      <div className='enquiry-form-success-box' style={{display: successMessageDisplay}}>
        <h2 className='title is-1'>Thank you for your travel request!</h2>
        <p>
          You’ll receive an email in the next few minutes confirming that one of our experienced Travel Consultants has got your travel enquiry. They’ll be in touch within 24 hours to chat about kick-starting your trip plans.
          <br/><br/>
          At Flight Centre, we’re committed to offering the best service for all of our customers. On the rare occasion you don’t hear from us within 24 hours (during normal working hours), please give us a call on 
          <span style={{display: 'block'}}>
            <a href="tel:08005870058" className="InfinityNumber desktop-header-phone-call" itemProp="telephone" data-attr="phone-number-landline">0800 587 0058</a>
          </span>
          <br/>
          Want to keep exploring in the meantime? Head to our website for more travel inspiration, ideas and advice!
          <br/><br/>
          If you’d like to keep exploring you could <a href="https://www.flightcentre.co.uk/" rel="noopener noreferrer" target="_blank">browse our website</a>.
        </p>
      </div>
      <div style={{display: errorMessageDisplay}}>
        <p style={{ textAlign: "center" }} className="is-size-5 has-text-danger">
          <br/>
          An unexpected error occurred, please try again.
        </p>
      </div>
            
      {
      !this.state.display_loading_gif &&
      <form className="enquiry-form" nls_fa_name="Holiday Package - General" action="" method="GET" acceptCharset="UTF-8" style={{display: inputFormDisplay}} onSubmit={this.handleSubmit}>
        
        <div className="columns is-multiline">
          <div className="column is-6">                  
            <div className="field">
              <div className="control">
                <div className="select is-fullwidth">
                  <select name="departName" required onChange={this.handleInputChange}>
                    <option value="">Where are you departing from? *</option>
                    <option value="London Heathrow">London Heathrow</option>
                    <option value="London Gatwick">London Gatwick</option>
                    <option value="Aberdeen">Aberdeen</option>
                    <option value="Belfast">Belfast</option>
                    <option value="Birmingham">Birmingham</option>
                    <option value="Bournemouth">Bournemouth</option>
                    <option value="Dublin">Dublin</option>
                    <option value="Edinburgh">Edinburgh</option>
                    <option value="Glasgow">Glasgow</option>
                    <option value="Leeds">Leeds</option>
                    <option value="Manchester">Manchester</option>
                    <option value="Newcastle">Newcastle</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="column is-6">                  
            <div className="field">
              <div className="control">
                <Select
                  value={this.state.destName}
                  onChange={this.handleDestSelectChange}
                  options={processDestOptions(destinations)}
                  placeholder={this.state.category === 'holidays' ? 'Your holiday destination *' : 'Your destination *'}
                  required
                  name='destName'
                  className='react-select-element'
                  classNamePrefix='react-select-prefix'
                />
              </div>
            </div>
          </div>
          <div className="column is-6">                  
            <div className="field">
              <div className="control">
                <DatePicker
                  selected={this.state.startDate}
                  selectsStart
                  minDate={new Date()}
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  onChange={this.handleDatepickerStartChange}
                  required
                  placeholderText='Departing date    dd/mm/yy *'
                />
              </div>
            </div>
          </div>
          <div className="column is-6">                  
            <div className="field">
              <div className="control">
                <DatePicker
                  selected={this.state.endDate}
                  selectsEnd
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  onChange={this.handleDatepickerEndChange}
                  minDate={this.state.startDate}
                  required
                  placeholderText='Returning date    dd/mm/yy *'
                />
              </div>
            </div>
          </div>

          <div className="column is-6">
            <div className="field">
              <div className="control">
                <div className="select is-fullwidth">
                  <select name="flight_class" required onChange={this.handleInputChange}>
                    <option value="">Travel class *</option>
                    <option value="economy">Economy Class</option>
                    <option value="premium">Premium Economy Class</option>
                    <option value="business">Business Class</option>
                    <option value="first">First Class</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="column is-6">
            <div className="field">
              <div className="control">
                <div className="select is-fullwidth">
                  <select name="numTravel" required onChange={this.handleInputChange}>
                    <option value="">How many travellers? *</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10+">10+</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className='column is-12'>
            <p>To get a better quote, tell us a bit more about your {this.state.category === 'holidays' ? 'holiday' : 'travel'} plans:</p>
            <textarea className="textarea" onChange={this.handleInputChange} name="quoteText" placeholder={ this.state.category === 'holidays' ? "What's your travel style, what things do you want to see and do, do you have any other requests?" : "Do you have a preferred airline? Are your travel dates flexible? Do you have any other travel requests?" }></textarea>
          </div>
        </div>

        <div className='columns'>
          <div className="column is-narrow cus_title_field">
            <div className="field">
              <div className="control">
                <div className="select is-fullwidth">
                  <select name="cus_title" required onChange={this.handleInputChange}>
                    <option value="">Title *</option>
                    <option value="Mr">Mr</option>
                    <option value="Mrs">Mrs</option>
                    <option value="Miss">Miss</option>
                    <option value="Ms">Ms</option>
                    <option value="Dr">Dr</option>
                  </select>                   
                </div>
              </div>  
            </div>
          </div>

          <div className="column">
            <div className="field">
              <div className="control">
                <input className="input" type="text" name="firstName" placeholder="First Name *" required onChange={this.handleInputChange} />
              </div>
            </div>
          </div>

          <div className="column">
            <div className="field">
              <div className="control">
                <input className="input" type="text" name="surname" placeholder="Last Name *" required onChange={this.handleInputChange} />
              </div>
            </div>
          </div>
        </div>

        <div className='columns'>
          <div className="column is-6">
            <div className="field">
              <div className="control">
                <PhoneInput country="UK" 
                  className="input" 
                  value={ this.state.phone } 
                  placeholder={ "Telephone" + (this.state.category === 'f&b' ? '' : ' *') }
                  onChange={ value => this.setState({ phone: value }) } 
                  required={ this.state.category === 'f&b' ? false : true }
                />
              </div>
            </div>
          </div>

          <div className="column is-6">
            <div className="field">
              <div className="control">
                <input className="input" type="email" placeholder="Email *" name="email" required onChange={this.handleInputChange} />
              </div>
            </div>
          </div>
        </div>

        <div className='columns'>
          <div className="column is-6">
            <div className="field">
              <div className="control">
                <p>Best time to call:</p>
                <div className="select is-fullwidth">
                  <select name="callbackTime" required onChange={this.handleInputChange}>
                    <option value="">Callback Time *</option>
                    <option value="Morning_9am-12pm">Morning: 9am - 12pm</option>
                    <option value="Afternoon_12pm-3pm">Afternoon: 12pm - 3pm</option>
                    <option value="LateAfternoon_3pm-6pm">Late Afternoon: 3pm - 6pm</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='columns newsletter-section'>
          <div className='column'>
            <p>While you're here, we'd love to send you our latest {this.state.category === 'holidays' ? 'holiday' : 'travel'} offers and information.
              Tick below to subscribe; you have the right to withdraw your consent from marketing communication at any time *
            </p>
            <br/>

            <div className="control">
              <label className="radio">
                <input type="radio" name="newsletter" value="1" required onChange={this.handleInputChange} />
                &nbsp;
                Yes please, I want to hear about the latest offers from Flight Centre
              </label>
            </div>
            <div className="control">
              <label className="radio">
                <input type="radio" name="newsletter" value="0" required onChange={this.handleInputChange} />
                &nbsp;
                No thank you, I don't want to hear about the latest offers from Flight Centre
              </label>
            </div>

            <p className='privacy'>We value our customers' privacy and will treat your personal details with the utmost care and respect.
              For information about how we handle your data, please read our <a target="_blank" rel="noopener noreferrer" href="https://www.flightcentre.co.uk/policies/privacy-policy">Privacy Policy</a>.
            </p>

            <br/>
            <div className="control is-fullwidth has-text-centered">
              { this.state.displayCanceledCaptchaMessage && <div className="canceled-captcha-message">Sorry, your message has not sent. Please click the button below to contact a Consultant.</div> }
              <button type="submit" className="button form-submit-button blue-button" disabled={this.state.recaptchaReady}>Send to an expert consultant</button>
            </div>
          </div>
        </div>

        <input type="hidden" name="keyword" value="" />
        <input type="hidden" name="eCommerceProdName" value={this.state.eCommerceProdName} />
        <input type="hidden" name="form_id" value={this.state.form_id} />
        <input className="destinationCountry" type="hidden" name="destinationCountry" value="" />
        <input type="hidden" name="utm_source" value={this.state.utm_source} />
        <input type="hidden" name="utm_medium" value={this.state.utm_medium} />
        <input type="hidden" name="utm_campaign" value={this.state.utm_campaign} />
        <input type="hidden" name="routing_code" value={this.state.routing_code} />

        <input type="hidden" name="bounceBackEmailTemplate" value="uk/fc/bb-message-enquiry.ftl" />
        <input type="hidden" name="consultantEmailTemplate" value="uk/fc/ct-message-enquiry.ftl" />
        <input type="hidden" name="enquiryCategory" value={this.state.enquiryCategory} />
        <input type="hidden" name="brand" value={this.state.brand} />          
        <input type="hidden" name="country" value="UK" />
        <input type="hidden" name="validationTemplate" value="uk/fc/basicHoneypotValidate.ftl" />
        <input type="hidden" name="failUrl" value="/contact-us/failure" />
        <input type="hidden" name="forwardUrl" value="/contact-us/success" />
        <input type="hidden" name="productEnquire" value="no" />

        <input type="hidden" name="pageUrl" value={this.state.pageUrl} />
        <input type="hidden" name="todaysDate" value={getCurrentDate()} />
        <input type="hidden" name="msclkid" value={this.state.msclkid} />
        <input type="hidden" name="gclid" value={this.state.gclid}/>
      </form>
      }

      {
      this.state.display_loading_gif &&
      <img className="form-loading-gif" alt="Loading" src={loadingGif} />
      }

      {
      this.state.phoneNumberAlert &&
      <div>
        <p style={{ textAlign: "center", marginTop: 20 }} className="is-size-6 has-text-danger">
          Please specify a valid UK phone number.
        </p>
      </div>
      }

      <div ref={this.setWrapperRef} className='recaptchav2-popup' style={{display: this.state.displayCaptchaV2Popup ? 'block' : 'none'}}>
        <div className='close' onClick={() => {this.closeCaptchaPopup()}}></div>
        <div className='text'>
          <div>Nearly there!</div>
          <p>Just one quick tick so we know you’re human!</p>
        </div>
        <Reaptcha
          ref={e => (this.captcha = e)}
          sitekey={this.recaptchaV2SiteKey}
          onLoad={this.recaptchaOnLoad}
          onVerify={this.recaptchaOnVerify}
          onRender={this.recaptchaOnRender}
          explicit
        />
        <button type="button" onClick={() => {this.submitFormRequest()}} className="button blue-button" disabled={!this.state.captchaVerified}>Send to an expert consultant</button>
      </div>
    </>
    );
  }
}

export default EnquiryFormContainer;
