import React from "react"
import { observer, inject } from "mobx-react"
import OutsideClickHandler from "react-outside-click-handler";
import CallBackWidget from "../components/cta-widgets/callback-widget"
import CallWidget from "../components/cta-widgets/call-widget"
import EmailScroll from "../components/cta-widgets/email-scroll"

@inject('store')
@observer
class FlightGridCTAPopup extends React.Component {
  render () {
    const { category } = this.props;
    const store = this.props.store
    var utcTime = new Date().getUTCHours()
    var isStoreTime = false
    var count_class = 'count-1'
    if (utcTime > 7 && utcTime < 18) {
      isStoreTime = true
      count_class = 'count-3'
    }
    return (
      <OutsideClickHandler onOutsideClick={() => {
        store.isOpenedCTA = 'none'
      }}
      >
      <div className={"flight-grid-cta-popup blue-chill " + count_class} style={{top: store.ctaPositionTop, display: store.isOpenedCTA}}>
        <div className="close-btn" onClick={() => {store.isOpenedCTA = 'none'}}></div>
        <div className="columns is-centered flight-grid-cta-container">
          {
          isStoreTime &&
          <CallWidget />
          }
          {
          isStoreTime &&
          <CallBackWidget category={category} />
          }
          <EmailScroll />
        </div>
      </div>
      </OutsideClickHandler>     
    )
  }
}
export default FlightGridCTAPopup   
